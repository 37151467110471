import api from "@/base/utils/request";

// 获取留言列表
export const leaveMsgList = data => {
  return api({
    url: "/admin/cyc/ask/index",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc/ask/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/ask/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc/ask/putBack",
    method: "post",
    data
  });
};

// 回复数据
export const Replay = data => {
  return api({
    url: "/admin/cyc/ask/reply",
    method: "post",
    data
  });
};