<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="60px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="关键字：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select v-model="status" placeholder="请选择状态">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      statusList: [
        { name: "待回复", id: 0 },
        { name: "已回复", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    status: {
      get() {
        return this.form.status == -1 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>