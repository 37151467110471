<template>
  <el-dialog title="回复" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical">
    <el-form size="medium" :model="_data" :rules="rules" ref="replayContent">
      <el-form-item prop="replayContent" style="margin-bottom: 0;">
        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" v-model="replayContent"
          placeholder="请输入回复内容"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="cancel('replayContent')">取消</el-button>
      <el-button size="small" type="primary" @click="submit('replayContent')" :loading="saveLoading">保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { Replay } from "../api/leaveMsg-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: Array,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        replayContent: [{ validator: this.replayContentPass, trigger: "blur" }],
      },
      replayContent: "",
      saveLoading: false,
    };
  },
  methods: {
    // 验证回复内容
    replayContentPass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入回复内容"));
      } else {
        callback();
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.$emit("addFeeConfig",this.form)
          const data = {};
          data.content_reply = this.replayContent;
          data.id = this.IDs;
          this.saveLoading = true;
          Replay(data)
            .then((res) => {
              this.$message.success("回复成功");
              setTimeout(() => {
                this.updateList();
                this.saveLoading = false;
                this.openDialog = false;
              }, 1000);
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  created() {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style>
</style>
